<template>
	<div>
		111
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				list : []
			}
		},
		created(){
			
		},
		methods:{
			
		},
	}
	// moduleList,
	// contentList,
	// addContent,
	// updateContent
</script>

<style>
</style>